import React from "react";
import { useUser } from "reactfire";
import Loading from "./components/pages/Loading";
import api from "./utils/api";
function AppAxiosAuth(props) {
  const {status, data: user, hasEmitted} = useUser(undefined, {initialData: {}});
  // console.log('======= AppAxiosAuth useUser ========', status,  hasEmitted, user);
  /**
   * Пока не прогрузится пользователь, нельзя будет вызвать метод firebase.auth().currentUser.getIdToken()
   * для установки токена в запросы к апи. Токен короткоживущий, поэтому его надо получать через
   * getIdToken(). Поэтому необходимо в комоненте
   */
  if ( status === 'loading' || hasEmitted === false ) {
    return <Loading/>
  }
  console.log('userFirebase',user);

  // Установка токена
  if (user) {
    api.interceptors.request.use(async config => {
      const token = await user.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config
    }, (error) => {
      return Promise.reject(error)
    })
  }

  return props.children;
}
export default AppAxiosAuth;