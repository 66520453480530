import React, { useEffect } from 'react';
import {Link} from "react-router-dom";

import $ from 'jquery';
window.jQuery = window.$ = $;
require('owl.carousel');

function Presentation(props) {
    useEffect(() => {
        document.title = `Входная презентация | Azimut Hotels`;
    });
    useEffect(() => {
        $('.promo').each(function(){
            var $box = $(this),
                $pictures = $('.promo__pictures-item', this),
                slider = {
                    $slider: $('.owl-carousel', $box),
                    options: {
                        mouseDrag: true,
                        dots: true,
                        nav: false,
                        navClass: ['owl-nav-button owl-nav-button_prev', 'owl-nav-button owl-nav-button_next' ],
                        navText: ['<svg class="icon-svg owl-nav-button__icon"><use xlink:href="#icon-arrow-3-l"></use></svg>', '<svg class="icon-svg owl-nav-button__icon"><use xlink:href="#icon-arrow-3-r"></use></svg>'],
                        margin: 30,
                        loop: false,
                        smartSpeed: 800,
                        items: 1,
                        animateIn: 'fadeIn',
                        animateOut: 'fadeOut',
                        auto: {
                            intervalCheck: null,
                            interval: null,
                            autoplay: true,
                            timeout: 5000
                        }
                    },

                    toNext: function() {
                        var self = this,
                            dots = {};

                        dots.$dots = $('.owl-dot', $box);
                        dots.$active = $('.owl-dot.active', $box);
                        dots.$next = dots.$active.next('.owl-dot');

                        if (!dots.$next.length) {
                            dots.$next = dots.$dots.eq(0);
                        }

                        self.$slider.trigger('to.owl.carousel', dots.$next.index());
                    },
                    autoplayStart: function() {
                        var self = this/*,
                            dots = {}*/;

                        self.autoplayStop();

                        self.options.auto.interval = setInterval(function(){
                            self.toNext();
                        }, self.options.auto.timeout);
                    },
                    autoplayStop: function() {
                        clearInterval(this.options.auto.interval);
                    },
                    init: function(){
                        var self = this;

                        self.$slider.on('drag.owl.carousel', function(event) {
                            if (self.options.auto.autoplay) {
                                self.autoplayStop();
                            }
                        });

                        self.$slider.on('translate.owl.carousel', function(event) {
                            $pictures.eq(event.item.index).addClass('active').siblings().removeClass('active');
                        });

                        self.$slider.on('translated.owl.carousel', function(event) {
                            if (self.options.auto.autoplay) {
                                self.autoplayStart();
                            }
                        });

                        self.$slider.on('initialized.owl.carousel', function(event) {
                            $box.addClass('initialized');
                            self.$slider.trigger('refresh.owl.carousel');
                        });

                        self.$slider.owlCarousel(self.options);

                        if (self.options.auto.autoplay) {
                            self.autoplayStart();
                        }

                        $('.promo__buttons-item_more .promo__buttons-item-link', $box).on('click', function(event){
                            self.toNext();

                            event.preventDefault();
                        });

                    }
                };

            slider.init();

        });

        // Размонтирование карусели
        return () => {
        };
    });
    return (
        <div className="promo">
            <div className="promo__pictures">
                <div className="promo__pictures-item active"
                     style={{backgroundImage: "url('/img/pics/1-1.jpg')"}}></div>
                <div className="promo__pictures-item" style={{backgroundImage: "url('/img/pics/1-2.jpg')"}}></div>
                <div className="promo__pictures-item" style={{backgroundImage: "url('/img/pics/1-3.jpg')"}}></div>
                <div className="promo__pictures-item" style={{backgroundImage: "url('/img/pics/1-4.jpg')"}}></div>
            </div>
            <div className="promo__part promo__part_1">
                <div className="cnt promo__part-cnt">
                    <div className="promo__logo">
                        <img src="img/logo.svg" alt="" title="" className="promo__logo-img"/>
                    </div>
                </div>
            </div>
            <div className="promo__part promo__part_2">
                <div className="cnt promo__part-cnt">
                    <div className="promo__slider">
                        <div className="promo__slider-list owl-carousel">
                            <div className="promo__slider-item">
                                <div className="promo__title">
                                    Интерактивное меню
                                </div>
                                <div className="promo__text">
                                    Заказ блюд и напитков в Ваш номер
                                </div>
                            </div>
                            <div className="promo__slider-item">
                                <div className="promo__title">
                                    Там, где Вы есть
                                </div>
                                <div className="promo__text">
                                    QR-код с меню в Вашем номере. Заказ принесут в номер, который Вы укажете в заказе.
                                </div>
                            </div>
                            <div className="promo__slider-item">
                                <div className="promo__title">
                                    Актуальная и полная информация
                                </div>
                                <div className="promo__text">
                                    Фотографии, состав и цены блюд всегда актуальны для Вашего отеля, времени заказа и номера.
                                </div>
                            </div>
                            <div className="promo__slider-item">
                                <div className="promo__title">
                                    Внимательный сервис
                                </div>
                                <div className="promo__text">
                                    Менеджер отеля свяжется с Вами после оформления заказа, чтобы уточнить время готовности и любые другие детали.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="promo__part promo__part_3">
                <div className="cnt promo__part-cnt">
                    <div className="promo__buttons">
                        <div className="promo__buttons-item">
                            <button
                                className="button-a button-a_bg-1 promo__buttons-item-link"
                                onClick={props.onChangePresentationShown}
                            >
                                К меню
                            </button>
                        </div>
                        {/* promo__buttons-item_more event.preventDefault() */}
                        <div className="promo__buttons-item promo__buttons-item_more">
                            <Link to="/pages/help"
                                  className="button-a button-a_bg-2 promo__buttons-item-link"
                            >
                                Узнать больше
                            </Link>
                        </div>
                    </div>
                    <div className="promo__remark">
                        Нажимая на кнопки “К меню” или “Узнать больше” Вы соглашаетесь с&nbsp;
                        <a href="https://azimuthotels.com/ru/terms-and-conditions" target="_blank" rel="noreferrer">
                            Политикой конфиденциальности
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Presentation;