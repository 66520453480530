import React, {useEffect, useState} from 'react';
import InputMask from "react-input-mask";
import {useHistory} from "react-router-dom";
import {useAuth, useUser} from "reactfire";
import api from "../../utils/api";
import Header from "../layout/Header";
import Nav from "../layout/Nav";

function Auth() {
    const auth = useAuth();
    const {status, data: user, hasEmitted} = useUser();
    const [form, setForm] = useState({
        name: '',
        phone: '',
        agreement: true,
        code: '',
    });
    const [state, setState] = useState('request-auth');
    const [errors, setErrors] = useState({});
    const [smsBlockTime, setSmsBlockTime] = useState(0);
    const [smsOutdate, setSmsOutdate] = useState(0);
    const [reapetSms, setReapetSms] = useState(false);
    const history = useHistory();

    //Title
    useEffect(() => {
        document.title = `Оформление заказа (2 из 3) | Azimut Hotels`;
    });

    useEffect(() => {
        let timer;
        if (smsBlockTime > 0) {
            timer = setTimeout(() => setSmsBlockTime(smsBlockTime - 1), 1000);
            setReapetSms(true);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    });

    function onChangeName(val) {
        const cloneForm = {...form};
        cloneForm.name = val;
        setForm(cloneForm);
    }

    function onChangePhone(val) {
        const cloneForm = {...form};
        cloneForm.phone = val;
        setForm(cloneForm);
        setSmsBlockTime(0);
        setSmsOutdate(0);
    }

    function onBeforeChangePhone({previousState, currentState, nextState}) {
        let valueClean = null,
            valueCleanNext = '',
            valueNext = '',
            mask = '+7 (999) 999-99-99';

        if (currentState) {
            valueClean = parseInt(currentState.value.replace(/[^0-9]/g, '')).toString();

            if (currentState.value.length > 18) {
                if (valueClean.length > 11) {
                    let digitCounter = 0;

                    for (let i = 0; i < valueClean.length; i++) {
                        if (i != 1) {
                            valueCleanNext = valueCleanNext + valueClean[i];
                        }
                    }
                    for (let i = 0; i < mask.length; i++) {
                        if (mask[i].match(/[0-9]/)) {
                            valueNext = valueNext + valueCleanNext[digitCounter];
                            digitCounter = digitCounter + 1;
                        } else {
                            valueNext = valueNext + mask[i];
                        }
                    }

                    nextState.value = valueNext;
                }
            }
        }

        return {
            ...nextState
        };
    }

    function onChangeAgreement(val) {
        const cloneForm = {...form};
        cloneForm.agreement = val;
        setForm(cloneForm);
    }

    function onChangeCode(val) {
        const cloneForm = {...form};
        cloneForm.code = val;
        setForm(cloneForm);
    }

    function onBackClick() {
        if (state === 'request-auth') {
            history.push('/');
        } else {
            setErrors({});
            setState('request-auth');
        }
    }

    function formatDate(date) {
        var d = new Date(date),
            minutes = d.getMinutes(),
            seconds = '' + d.getSeconds();

        if (seconds < 10) {seconds = "0"+seconds;}

        return [minutes, seconds].join(':');
    }

    function sendSmsCode(){
        const cloneForm = {...form};
        api.post('/user/request-auth', cloneForm).then((res) => {
            if (res.data) {
                if (res.data.smsBlockTime) {
                    setSmsBlockTime(res.data.smsBlockTime);
                }
                const cloneForm = {...form};
                cloneForm.code = '';
                setForm(cloneForm);
                setState('auth');
            } else {
                setErrors({'error': ['Ошибка отправки смс, попробуйте позже.']});
            }
        }).catch(err => {
            setErrors(err.response.data.errors);
        })
    }

    function resendingSms(){
        setSmsOutdate(0);
        setErrors({});
        sendSmsCode();
    }

    // Отправка формы
    async function onSubmit() {
        setReapetSms(false);
        setSmsBlockTime(0);
        setSmsOutdate(0);
        setErrors({});
        if (state === 'request-auth') {
            sendSmsCode();
        } else if (state === 'auth') {
            const cloneForm = {...form};
            api.post('/user/auth', cloneForm).then((res) => {
                if (res.data && res.data.token) {
                    auth.signInWithCustomToken(res.data.token)
                        .then((userCredential) => {
                            // Signed in
                            var user = userCredential.user;
                            // ...
                            history.goBack();
                        })
                        .catch((error) => {
                            setErrors({'error': [error.message]});
                        });
                } else {
                    if (res.data.smsOutdate) {
                        setSmsOutdate(res.data.smsOutdate);
                    } else {
                        setErrors({'error': ['Ошибка отправки смс, попробуйте позже.']});
                        setState('request-auth');
                    }
                }
                const cloneForm = {...form};
                cloneForm.code = '';
                setForm(cloneForm);
            }).catch(err => {
                setErrors(err.response.data.errors);
                const cloneForm = {...form};
                cloneForm.code = '';
                setForm(cloneForm);
            })
        }
    }


    // Шаблоны
    const headerItem1 = (
        <a href={'#'} onClick={onBackClick} className="button-back-top">
            <svg className="icon-svg button-back-top__icon">
                <use xlinkHref="#icon-arrow-3"/>
            </svg>
        </a>
    );
    const headerItem3 = (
        <div className="title header__title">
            Авторизация
        </div>
    );

    return (
        <React.Fragment>
            <Header item1={headerItem1} item3={headerItem3}/>
            <Nav/>
            <div className="content content_padding-1">
                <div className="form auth-form"><div className="cnt auth-form__cnt">
                    {/* Имя */}
                    <div style={{display: (state === 'request-auth') ? 'block' : 'none'}}
                         className={`form__item`}>
                        <div className="field-form form__field-form">
                            <input type="text"
                                   className={`input-text field-form__input-text`}
                                   placeholder="Имя"
                                   value={form.name}
                                   onChange={e => onChangeName(e.target.value)}
                            />
                        </div>
                    </div>

                {/* Телефон */}
                <div style={{display: (state === 'request-auth') ? 'block' : 'none'}}
                     className="form__item">
                    <div className={`field-form form__field-form`}>
                        <InputMask
                            className={`input-text field-form__input-text mask-input-phone`}
                            mask="+7 (999) 999-99-99"
                            placeholder="Телефон"
                            value={form.phone}
                            onChange={e => onChangePhone(e.target.value)}
                            beforeMaskedStateChange={onBeforeChangePhone}
                        />
                    </div>
                </div>

                {/* Флажок согласия с политикой конфиденциальности */}
                <div style={{display: (state === 'request-auth') ? 'block' : 'none'}}
                     className="form__item">
                    <div className={`field-form form__field-form`}>
                        <label className="input-button input-button_1 label-input__input-button">
                          <input
                              type='checkbox'
                              checked={form.agreement}
                              onChange={e => onChangeAgreement(e.target.checked)}
                          />
                          <div className="input-button__icon">
                            <svg className="icon-svg input-button__icon-mark">
                              <use xlinkHref="#icon-check"/>
                            </svg>
                          </div>
                          <div className="input-button__text">
                            Я даю свое согласие на обработку <a href='https://azimuthotels.com/ru/terms-and-conditions'
                                                                target='_blank'>Персональных данных</a> и
                            согласен с <a href='https://azimuthotels.com/ru/terms-and-conditions' target='_blank'>Политикой
                            конфиденциальности</a>
                          </div>
                        </label>
                    </div>
                </div>

                {/* Смс-код */}
                <div style={{display: (state === 'auth') ? 'block' : 'none'}}
                     className={`form__item`}>
                    <p>Мы отправили SMS с кодом на указанный Вами номер телефона ({form.phone}). Введите код в поле
                        ниже. Если Вы ошиблись в номере телефона или не получили код&nbsp;—&nbsp;<a href="#"
                                                                                                    onClick={onBackClick}>вернитесь
                            назад</a> и укажите правильный номер.</p>
                    <div className="field-form form__field-form">
                        <InputMask
                            className={`input-text field-form__input-text mask-input-phone`}
                            placeholder="Код из SMS"
                            value={form.code}
                            onChange={e => onChangeCode(e.target.value)}
                        />
                    </div>

                </div>

                {
                    ((state === 'auth') && smsBlockTime) ?
                    <p className='form__caption_error'>
                        Отправка кода пока недопустима.
                        Вы сможете отправить новый
                        код через {formatDate(smsBlockTime*1000)}
                    </p>
                    : null
                }

                {
                    (!smsBlockTime && reapetSms && (state === 'auth')) ?
                    <p>
                        <a href='#' onClick={resendingSms}>Нажмите сюда</a>, чтобы запросить код повторно
                    </p>
                    : null
                }

                {
                    (smsOutdate && (state === 'auth')) ?
                    <p>
                        Код устарел. <a href='#' onClick={resendingSms}>Нажмите сюда</a>, чтобы запросить код повторно
                    </p>
                    : null
                }

                {/* Ошибки валидации */}
                {Object.keys(errors).length > 0 &&
                <div className="form__errors">
                    {Object.keys(errors).map(field => {
                        return errors[field].map((error, index) => {
                            return <div key={index}>{error}</div>
                        })
                    })}
                </div>
                }


                <button type="submit"
                        className="button-style-reset button-a order__button-submit"
                        onClick={onSubmit}
                >
                    Отправить
                </button>
            </div></div>
            </div>
        </React.Fragment>
    );
}

export default Auth;