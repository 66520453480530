import React, { useEffect } from 'react';
import Header from "../layout/Header";
import Nav from "../layout/Nav";

function RulesPrivacy() {
    useEffect(() => {
        document.title = `Правила сервиса | Azimut Hotels`;
    });

    return (
        <React.Fragment>

            <Header/>
            <Nav/>

            <div className="content">
                <div className="product-card">
                    <div className="cnt product-card__cnt">
                        <div className="product-card__part product-card__part_1">
                            <div className="product-card__picture">
                                <img src={`${process.env.REACT_APP_SITE_URL}images/azm_c.jpg`}
                                     alt=""
                                     title=""
                                     className="product-card__picture-img"/>
                            </div>
                            <div className="product-card__part product-card__part_3"><h1
                                className="product-card__title">Страница в разработке</h1></div>
                        </div>
                        <div className="product-card__part product-card__part_2">
                            <div className="product-card__description">Страница находится в разработке.</div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default RulesPrivacy;