import axios from 'axios';
//import firebase from "firebase";

const ax = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: 'json',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
});

// Перенесено в AppAxiosAuth
/*ax.interceptors.request.use(async config => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
        const token = await currentUser.getIdToken();
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config
}, (error) => {
    return Promise.reject(error)
})*/
export default ax;