import React, { useEffect } from 'react';
import {Link} from "react-router-dom";

function CheckoutSuccess() {
    useEffect(() => {
        document.title = `Заказ оформлен! | Azimut Hotels`;
    });
    const orderId = sessionStorage.getItem('orderSuccessId');
    return (
        <div className="content">
            <div className="message message_order-success">
                <div className="cnt message__cnt">
                    <div className="headline message__headline">
                        <h1 className="headline__title headline__title_1">
                            Заказ #{orderId} оформлен!
                        </h1>
                    </div>
                    <div className="message__picture">
                        <div className="message__picture-circle">
                            <svg className="icon-svg message__picture-icon">
                                <use xlinkHref="#icon-check"></use>
                            </svg>
                        </div>
                    </div>

                    <div className="message__text">
                        <h2>
                            Спасибо за заказ
                        </h2>

                        <p>
                            Менеджер свяжется с Вами в ближайшее время для подтверждения заказа и уточнения времени готовности.
                        </p>
                    </div>

                    <div className="message__buttons">
                        {/*<div className="message__buttons-item">
                            <a href="#" className="button-a message__buttons-item-link">
                                Детали заказа
                            </a>
                        </div>*/}
                        <div className="message__buttons-item">
                            <Link to="/" className="button-a button-a_bg-3 message__buttons-item-link">
                                Обратно к меню
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CheckoutSuccess;