import React from 'react';

function Loading() {
    return (
        <div className="content">
            <div className="message message_order-success">
                <div className="cnt message__cnt">
                    Загрузка...
                </div>
            </div>
        </div>
    );
}
export default Loading;