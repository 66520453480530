import React, {useContext} from 'react';
import {NavLink} from "react-router-dom";
import BasketServiceContext from "../context/BasketServiceContext";

function Nav() {
    const basketService = useContext(BasketServiceContext);
    const countBasketPositions = basketService.getCountPositions()
    return <nav className="menu-main">
        <div className="cnt menu-main__cnt">
            <div className="menu-main__list">
                <div className="menu-main__item">
                    <NavLink to="/" exact className="menu-main__item-link">
                        <div className="menu-main__item-picture">
                            <svg className="icon-svg menu-main__item-picture-icon">
                                <use xlinkHref="#icon-home"></use>
                            </svg>
                        </div>
                        <div className="menu-main__item-text">
                            Главная
                        </div>
                    </NavLink>
                </div>
                <div className="menu-main__item">
                    <NavLink to="/catalog" className="menu-main__item-link">
                        <div className="menu-main__item-picture">
                            <svg className="icon-svg menu-main__item-picture-icon">
                                <use xlinkHref="#icon-menu"></use>
                            </svg>
                        </div>
                        <div className="menu-main__item-text">
                            Меню
                        </div>
                    </NavLink>
                </div>
                <div className="menu-main__item">
                    <NavLink to="/checkout/paytype" className="menu-main__item-link">
                        <div className="menu-main__item-picture">
                            <svg className="icon-svg menu-main__item-picture-icon">
                                <use xlinkHref="#icon-cart"></use>
                            </svg>
                        </div>
                        <div className="menu-main__item-text">
                            Корзина
                        </div>
                        {countBasketPositions > 0 &&
                            <div className="menu-main__item-amount">
                                {countBasketPositions}
                            </div>
                        }
                    </NavLink>
                </div>
                <div className="menu-main__item">
                    <NavLink to="/cabinet/orders" className="menu-main__item-link">
                        <div className="menu-main__item-picture">
                            <svg className="icon-svg menu-main__item-picture-icon">
                                <use xlinkHref="#icon-orders"></use>
                            </svg>
                        </div>
                        <div className="menu-main__item-text">
                            Заказы
                        </div>
                    </NavLink>
                </div>
                <div className="menu-main__item">
                    <NavLink to="/pages/help" className="menu-main__item-link">
                        <div className="menu-main__item-picture">
                            <svg className="icon-svg menu-main__item-picture-icon">
                                <use xlinkHref="#icon-help"></use>
                            </svg>
                        </div>
                        <div className="menu-main__item-text">
                            Помощь
                        </div>
                    </NavLink>
                </div>
            </div>
        </div>
    </nav>
}
export default Nav;