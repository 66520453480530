import React from 'react';
import {Link} from "react-router-dom";

function Header(props) {
    return <header className="header">
        <div className="cnt header__cnt">
            <div className="header__grid header__grid_1">
                <div className="header__grid-item header__grid-item_1">
                    {props.item1 !== undefined ?
                        props.item1 :
                        <Link to="/" className="button-back-top">
                            <svg className="icon-svg button-back-top__icon">
                                <use xlinkHref="#icon-arrow-3"/>
                            </svg>
                        </Link>
                    }
                </div>
                <div className="header__grid-item header__grid-item_3">
                    {props.item3}
                </div>
                <div className="header__grid-item header__grid-item_2">
                    {props.item2}
                </div>
            </div>
        </div>
    </header>
}
export default Header;