import React, {useEffect, useState} from 'react';
import {Link, Redirect, useParams} from "react-router-dom";
import Header from "../layout/Header";
import Nav from "../layout/Nav";
import api from "../../utils/api";
import Page404 from "./Page404";
import Loading from "./Loading";
import {useHistory} from "react-router-dom";
import {useUser} from 'reactfire';

function CabinetOrder() {
  const [order, setOrder] = useState(null);
  const [page404, setPage404] = useState(false);
  const history = useHistory();
  let { orderId } = useParams();
  const {status, data: user, hasEmitted} = useUser();

  useEffect(() => {
    if (orderId) {
      document.title = `Заказ № ${orderId} | Azimut Hotels`;
    }
  }, [orderId]);
  // useEffect(() => {
  //   if (!user) {
  //     history.replace("/checkout/contact");
  //   }
  // }, []);

  // Загрузка данных
  // Заказы
  useEffect(() => {
      api.get(`/orders/${orderId}`).then(res=>{
        setOrder(res.data);
      }).catch(err=>{
        console.log(err)
        setPage404(true);
      })

  },[orderId]);

  if (!user) {
    return <Redirect to="/checkout/contact"/>
  }

  // Шаблоны
  if (page404) {
    return <Page404/>
  } else if (!order) {
    return <Loading/>
  }

  const headerItem1 = (
    <Link to="/cabinet/orders" className="button-back-top">
      <svg className="icon-svg button-back-top__icon">
        <use xlinkHref="#icon-arrow-3"/>
      </svg>
    </Link>
  );

  return (
    <React.Fragment>

      <Header item1={headerItem1} item2=""/>
      <Nav/>
      <div className="content">
        <div className="order-card">
          <div className="cnt order-card__cnt">
            <div className="headline order-card__headline">
              <h1 className="headline__title headline__title_2 order-card__title">
                Заказ № {order.id}
              </h1>
            </div>

            <div className="order-card__grid order-card__grid_1">
              <div className="order-card__grid-item">
                <div className="order-card__caption">
                  Дата и время заказа
                </div>
                <div className="order-card__value">
                  <div className="order-card__value-part order-card__value-part_1">
                    {(new Date(order.created_at)).toLocaleDateString('ru-RU', {year: 'numeric', month: 'long', day: 'numeric'})}
                  </div>
                  <div className="order-card__value-part order-card__value-part_2">
                    {(new Date(order.created_at)).toLocaleTimeString('ru-RU', { hour: 'numeric', minute: 'numeric'})}
                  </div>
                </div>
              </div>
              <div className="order-card__grid-item">
                <div className="order-card__caption">
                  Стоимость
                </div>
                <div className="order-card__value">
                  <div className="order-card__value-part order-card__value-part_1">
                    {order.priceAmount} ₽
                  </div>
                  <div className="order-card__value-part order-card__value-part_2">
                    {order.orderPayType.name}
                  </div>
                </div>
              </div>
              <div className="order-card__grid-item">
                <div className="order-card__caption">
                  Место получения
                </div>
                <div className="order-card__value">
                  <div className="order-card__value-part order-card__value-part_1">
                    Номер
                  </div>
                  <div className="order-card__value-part order-card__value-part_2">
                    {order.placeText}
                  </div>
                </div>
              </div>
              <div className="order-card__grid-item">
                <div className="order-card__caption">
                  Состав заказа
                </div>
                <div className="order-card__products">
                  <div className="order-card__products-grid order-card__products-grid_1">

                    {order.basketSnapshot.map((basketItem, index) => {
                      return <div className="order-card__products-grid-item" key={index}>
                        <div className="product-mini-a">
                          <div className="product-mini-a__title">
                            {basketItem.name}
                          </div>
                          <div className="product-mini-a__detail">
                            {basketItem.count} шт, {basketItem.price} ₽
                          </div>
                        </div>
                      </div>
                    })}

                  </div>
                </div>
              </div>
            </div>

            {/*<div className="order-card__button-discard">
              <a href="#" className="button-a button-a_bg-4 order-card__button-discard-link">
                Отменить
              </a>
            </div>*/}

          </div>
        </div>
      </div>

      {/* Страница находится в разработке. */}
      {/*<div className="content">
          <div className="product-card">
              <div className="cnt product-card__cnt">
                  <div className="product-card__part product-card__part_1">
                      <div className="product-card__picture">
                          <img src={`${process.env.REACT_APP_SITE_URL}images/azm_c.jpg`}
                               alt=""
                               title=""
                               className="product-card__picture-img"/>
                      </div>
                      <div className="product-card__part product-card__part_3"><h1
                          className="product-card__title">Страница в разработке</h1></div>
                  </div>
                  <div className="product-card__part product-card__part_2">
                      <div className="product-card__description">Страница находится в разработке.</div>
                  </div>

              </div>
          </div>
      </div>*/}
    </React.Fragment>
  );
}

export default CabinetOrder;