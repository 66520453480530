import React, {useEffect, useState} from 'react';
import {Link, useParams } from 'react-router-dom';
import Header from "../layout/Header";
import Nav from "../layout/Nav";
import api from "../../utils/api";
import Page404 from "./Page404";
import Loading from "./Loading";
import Product from "../blocks/Product";

function Catalog(props) {
    const [categories, setCategories] = useState(null);
    const [category, setCategory] = useState(null);
    const [goods, setGoods] = useState(null);
    const [page404, setPage404] = useState(false);
    let { catId } = useParams();

    // Загрузка данных
    // Категории
    useEffect(() => {
        const params = {locationId: props.locationId, isAdult: props.isAdult}
        // Текущая(родительская) категория
        if (catId) {
            params.parentId = catId;
            api.get(`/categories/${catId}`).then(res=>{
                setCategory(res.data);
            }).catch(err=>{
                setPage404(true);
            })
        } else {
            setCategory(null);
        }
        api.get(`/categories`, {params} ).then(res=>{
            setCategories(res.data);
        }).catch(err=>{
            setPage404(true);
        })
    },[props.isAdult, props.locationId, catId]);
    // Продукты
    useEffect(() => {
        if (catId) {
            const params = {categoryId: catId, isAdult: props.isAdult};
            api.get(`/goods`, {params}).then(res=>{
                setGoods(res.data);
            }).catch(err=>{
                setPage404(true);
            })
        } else {
            setGoods(null);
        }
    },[props.isAdult, props.locationId, catId]);


    // Title
    useEffect(() => {
        if (category) {
            document.title = `${category.name} | Azimut Hotels`;
        } else {
            document.title = `Разделы меню | Azimut Hotels`;
        }
    });

    // Шаблоны
    if (page404) {
        return <Page404/>
    } else if (!categories || (catId && !category) || (catId && !goods)) {
        return <Loading/>
    }

    const headerItem1 = (
        <Link to={category ? (category.parentId ? `/catalog/${category.parentId}` : `/catalog`) : `/`} className="button-back-top">
            <svg className="icon-svg button-back-top__icon">
                <use xlinkHref="#icon-arrow-3"></use>
            </svg>
        </Link>
    );
    return (
        <React.Fragment>

            <Header item1={headerItem1} item2=""/>
            <Nav/>

            <div className="content">
                <div className="headline">
                    <div className="cnt headline__cnt">
                        <h1 className="headline__title">
                            {category ? category.name : 'Разделы'}
                        </h1>
                    </div>
                </div>

                {/* Категории */}
                {categories.length > 0 &&
                    <div className="categories">
                        <div className="cnt categories__cnt">
                            <div className="categories__grid categories__grid_2">

                                {categories.map(cat => {
                                    return <div className="categories__grid-item" key={cat.id}>
                                        <Link to={`/catalog/${cat.id}`} className="category-mini-a">
                                            <div className="category-mini-a__picture">
                                                <div className="category-mini-a__picture-inner">
                                                    <img src={cat.image} alt={cat.name} title={cat.name}
                                                         className="category-mini-a__picture-img"/>
                                                </div>
                                            </div>
                                            <div className="category-mini-a__data">
                                                <div className="category-mini-a__title">
                                                    {cat.name}
                                                </div>
                                                {cat.goodsCount > 0 &&
                                                    <div className="category-mini-a__detail">
                                                        {cat.goodsCount} позиции
                                                    </div>
                                                }
                                                <div className="category-mini-a__button-more">
                                                    <svg className="icon-svg category-mini-a__button-more-icon">
                                                        <use xlinkHref="#icon-arrow-2"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                })}

                            </div>
                        </div>
                    </div>
                }

                {/* Продукты */}
                {(goods && goods.length) > 0 &&
                    <div className="products">
                        <div className="cnt products__cnt">
                            <div className="products__grid products__grid_1">

                                {/* Список продуктов */}
                                {goods.map(goods => {
                                    return <Product goods={goods} key={goods.id}/>
                                })}

                            </div>
                        </div>
                    </div>
                }

            </div>

        </React.Fragment>
    );
}
export default Catalog;