import React, {useEffect, useState, useContext} from 'react';
import Page404 from "./Page404";
import Loading from "./Loading";
import { Link, useParams } from "react-router-dom";
import Header from "../layout/Header";
import Nav from "../layout/Nav";
import api from "../../utils/api";
import BasketServiceContext from "../context/BasketServiceContext";

function Goods(props) {
    const [goods, setGoods] = useState(null);
    const [category, setCategory] = useState(null);
    const [page404, setPage404] = useState(false);
    const [scheduleMessage, setScheduleMessage] = useState(null);
    let { goodsId } = useParams();
    const basketService = useContext(BasketServiceContext);

    // Загрузка данных
    // Продукт
    useEffect(() => {
        const params = {locationId: props.locationId, isAdult: props.isAdult};
        api.get(`/goods/${goodsId}`, {params}).then(res=>{
            if (res.data.error && ('schedule' === res.data.type) ) {
                setScheduleMessage(res.data.message);
                setGoods(res.data.model);
            } else {
                setGoods(res.data);
            }
        }).catch(err=>{
            console.log('err2', err);
            setPage404(true);
        })
    },[goodsId]);

    // Title
    useEffect(() => {
        if (goods) {
            document.title = `${goods.name} | Azimut Hotels`;
        }
    }, [goods]);

    // Шаблоны
    if (page404) {
        return <Page404/>
    } else if (!goods) {
        console.log(goods);
        return <Loading/>
    }

    const headerItem1 = (
        <Link to={`/catalog/${goods.categoryId}`} className="button-back-top">
            <svg className="icon-svg button-back-top__icon">
                <use xlinkHref="#icon-arrow-3"></use>
            </svg>
        </Link>
    );

    const count = basketService.getCountGoods(goods.id);
    return (
        <React.Fragment>

            <Header item1={headerItem1} item2=""/>
            <Nav/>

            <div className="content">
                <div className="product-card">
                    <div className="cnt product-card__cnt">
                        <div className="product-card__part product-card__part_1">
                            <div className="product-card__picture">
                                <img src={goods.image} alt={goods.name} title={goods.name} className="product-card__picture-img"/>
                            </div>
                            <div className="product-card__part product-card__part_3">
                                <div className="product-card__category">
                                    {goods.categoryName}
                                </div>
                                <h1 className="product-card__title">
                                    {goods.name}
                                </h1>
                                {goods.weight.length > 0 &&
                                    <div className="product-card__detail">
                                        {goods.weight.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {goods.weightLabel ? goods.weightLabel : 'г'}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="product-card__part product-card__part_2">
                            <div className="product-card__description" dangerouslySetInnerHTML={{ __html: goods.description }} />
                        </div>

                        {/* Корзина */}
                        <div className="product-card__bar">
                            <div className="cnt product-card__bar-cnt">

                                {scheduleMessage &&
                                    <span className="product-card__bar-inner">
                                        <div className="product-card__bar-grid">
                                            <div className="product-card__bar-grid-item product-card__bar-grid-item_1">
                                                <div className="product-card__price">
                                                    {scheduleMessage}
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                }
                                {!scheduleMessage && count === 0 &&
                                    <button className="product-card__bar-inner"
                                            onClick={(e) => basketService.addGoods(goods)}
                                    >
                                        <div className="product-card__bar-grid">
                                            <div className="product-card__bar-grid-item product-card__bar-grid-item_1">
                                                <div className="product-card__price">
                                                    {goods.price} ₽
                                                </div>
                                            </div>
                                            <div className="product-card__bar-grid-item product-card__bar-grid-item_2">
                                                <div className="product-card__button-cart">
                                                    <svg className="icon-svg product-card__button-cart-icon">
                                                        <use xlinkHref="#icon-cart-1"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                }
                                {!scheduleMessage && count > 0 &&
                                    <div className="product-card__bar-inner">
                                        <div className="product-card__bar-grid">
                                            <div className="product-card__bar-grid-item product-card__bar-grid-item_1">
                                                <div className="product-card__price">
                                                    {goods.price} ₽
                                                </div>
                                            </div>
                                            <div className="product-card__bar-grid-item product-card__bar-grid-item_2">
                                                <div className="amount-mini product-card__amount" data-suffix=" шт">
                                                    <input type="text" value={`${count} шт`} className="amount-mini__input-text"
                                                           disabled/>
                                                    <button
                                                       className="button-style-reset amount-mini__button amount-mini__button_decrement"
                                                       onClick={(e) => basketService.delGoods(goods.id)}
                                                    />
                                                    <button
                                                       className="button-style-reset amount-mini__button amount-mini__button_increment"
                                                       onClick={(e) => basketService.addGoods(goods)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>

                    </div>
                </div>


            </div>

        </React.Fragment>
    );
}
export default Goods;